import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import Modal from "react-modal";
import { configureStore, createSlice } from "@reduxjs/toolkit";
import Grid from "@mui/material/Grid2";
import TextField from "@mui/material/TextField";
import { ThemeProvider } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { InputTheme } from "../../common/utils/ThemeUtils";
import {
  createChecklist,
  createItemListing,
  fetchSummaries,
} from "../../common/client/ChecklistClient";
import {
  hideLoading,
  showLoading,
} from "../../common/dialog/ThreeCirclesLoading";
import styles from "../style/checklist.module.css";

Modal.setAppElement("#root");

const slice = createSlice({
  name: "checklistState",
  initialState: {
    target: undefined,
  },
  reducers: {
    defineTarget(state, action) {
      state.target = action.payload;
    },
    resetTarget(state) {
      state.target = undefined;
    },
  },
});

const store = configureStore({
  reducer: {
    checklistState: slice.reducer,
  },
});

const contentStyle = {
  content: {
    top: "15vh",
    bottom: "15vh",
    left: "20vw",
    right: "20vw",
    paddingTop: "5vh",
    paddingBottom: "5vh",
    paddingLeft: "3vw",
    paddingRight: "3vw",
  },
  overlay: {
    zIndex: 2,
  },
};

const closeStyle = {
  position: "absolute",
  right: "1vh",
  top: "1vh",
};

const showChecklistDialog = (uuid, name) => {
  store.dispatch(slice.actions.defineTarget({ uuid, name }));
};

const CreateChecklistView = (props) => {
  const [show, setShow] = useState(false);
  const [name, setName] = useState();
  const [quantity, setQuantity] = useState();

  if (show) {
    return (
      <Grid size={12}>
        <div className={styles.createContent}>
          <div className={styles.createInput}>
            <TextField
              id="checklist-name"
              name="checklist-name"
              label="Type checklist name"
              variant="outlined"
              size="small"
              sx={{ flex: 1, marginRight: "1vw" }}
              onChange={(event) => {
                setName(event.target.value);
              }}
            />
            <TextField
              id="requested-quantity"
              name="requested-quantity"
              variant="outlined"
              label="Select quantity"
              size="small"
              slotProps={{
                htmlInput: {
                  type: "number",
                },
              }}
              onChange={(event) => {
                setQuantity(event.target.value);
              }}
            />
          </div>
          <div className={styles.createControl}>
            <NavLink
              to={"#"}
              style={{ marginRight: "1vw" }}
              className={styles.button}
              onClick={() => setShow(false)}
            >
              abort
            </NavLink>
            <NavLink
              to={"#"}
              className={styles.button}
              onClick={() => {
                if (name && quantity && quantity > 0) {
                  showLoading();
                  const checklist = {
                    metadata: {
                      name: name,
                    },
                    items: [
                      {
                        uuid: props.item,
                        fulfillment: {
                          quantity: quantity,
                        },
                      },
                    ],
                  };
                  createChecklist(checklist)
                    .then((uuid) => {
                      setName(undefined);
                      setQuantity(undefined);
                      props.onComplete(uuid, name);
                      setShow(false);
                    })
                    .finally(hideLoading);
                }
              }}
            >
              save
            </NavLink>
          </div>
        </div>
      </Grid>
    );
  } else {
    return (
      <Grid size={12}>
        <div style={{ flex: 1, display: "flex", justifyContent: "flex-end" }}>
          <NavLink
            to={"#"}
            className={styles.button}
            onClick={() => setShow(true)}
          >
            create checklist
          </NavLink>
        </div>
      </Grid>
    );
  }
};

const CreateItemListingView = (props) => {
  const { uuid, name, item } = props;
  const [quantity, setQuantity] = useState();

  return [
    <Grid size={7} sx={{ display: "flex", alignItems: "center" }}>
      <label className={styles.itemLabel}>{name}</label>
    </Grid>,
    <Grid size={3}>
      <TextField
        id="requested-quantity"
        name="requested-quantity"
        variant="outlined"
        label="Select quantity"
        size="small"
        value={quantity}
        slotProps={{
          htmlInput: {
            type: "number",
          },
        }}
        onChange={(event) => {
          setQuantity(event.target.value);
        }}
      />
    </Grid>,
    <Grid
      size={2}
      sx={{ display: "flex", alignItems: "center", justifyContent: "end" }}
    >
      <NavLink
        to={"#"}
        style={{ marginRight: "1vw" }}
        className={styles.button}
        onClick={() => {
          if (quantity && quantity > 0) {
            showLoading();
            createItemListing(uuid, {
              uuid: item,
              fulfillment: { quantity: quantity },
            })
              .then(() => setQuantity(undefined))
              .finally(hideLoading);
          }
        }}
      >
        add
      </NavLink>
    </Grid>,
  ];
};

const ChecklistDialog = () => {
  const [target, setTarget] = useState();
  const [checklists, setChecklists] = useState([]);

  store.subscribe(() => {
    const { target } = store.getState().checklistState;
    setTarget(target);
  });

  const onClose = () => {
    store.dispatch(slice.actions.resetTarget());
  };

  useEffect(() => {
    fetchSummaries().then(setChecklists);
  }, []);

  return (
    <Modal
      isOpen={target !== undefined}
      style={contentStyle}
      onRequestClose={onClose}
    >
      <IconButton style={closeStyle} onClick={onClose}>
        <CloseIcon />
      </IconButton>
      <ThemeProvider theme={InputTheme}>
        <Grid container rowSpacing={3} sx={{ flex: 1 }}>
          <Grid size={12}>
            <div style={{ flex: 1, textAlign: "center", paddingBottom: "1vh" }}>
              <label className={styles.label}>
                Adding item "{target?.name?.default}" to checklist
              </label>
            </div>
          </Grid>
          {checklists.map((checklist) => {
            return (
              <CreateItemListingView
                uuid={checklist.metadata.uuid}
                name={checklist.metadata.name}
                item={target?.uuid}
              />
            );
          })}
          <CreateChecklistView
            item={target?.uuid}
            onComplete={(uuid, name) =>
              setChecklists([...checklists, { uuid, name }])
            }
          />
        </Grid>
      </ThemeProvider>
    </Modal>
  );
};

export { showChecklistDialog };

export default ChecklistDialog;
