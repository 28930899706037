import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid2";
import ClearIcon from "@mui/icons-material/Clear";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import IconButton from "@mui/material/IconButton";
import NavigationPanelView from "../../navigation/component/NavigationPanelView";
import SearchView from "../../navigation/component/SearchView";
import ConfirmableActionButton from "../component/ConfirmableActionButton";
import {
  hideLoading,
  showLoading,
} from "../../common/dialog/ThreeCirclesLoading";
import moment from "moment";
import { removeChecklist } from "../client/AxiosClient";
import { fetchSummaries } from "../../common/client/ChecklistClient";
import { buildPrice } from "../utils/PricingUtils";
import styles from "../style/checklist.module.css";

const ChecklistContent = (props) => {
  const navigate = useNavigate();
  const [checklists, setChecklists] = useState();

  useEffect(() => {
    setChecklists(props.checklists);
  }, props.checklists);

  if (checklists) {
    if (checklists.length > 0) {
      return (
        <Grid
          container
          columns={13}
          className={styles.scrollable}
          sx={{ borderBottom: "none", borderRadius: "10px 10px 0 0" }}
        >
          <Grid size={1} className={styles.headerLabel}>
            Id
          </Grid>
          <Grid size={4} className={styles.headerLabel}>
            Name
          </Grid>
          <Grid size={2} className={styles.headerLabel}>
            Created
          </Grid>
          <Grid size={1} className={styles.headerLabel}>
            Suppliers
          </Grid>
          <Grid size={1} className={styles.headerLabel}>
            Variants
          </Grid>
          <Grid size={1} className={styles.headerLabel}>
            Quantity
          </Grid>
          <Grid size={2} className={styles.headerLabel}>
            Estimated amount
          </Grid>
          <Grid size={1} className={styles.headerLabel}></Grid>
          {checklists.map((checklist) => {
            const metadata = checklist.metadata;
            return [
              <Grid size={1} className={styles.rowLabel}>
                {`#${metadata.uuid.substring(0, 8)}`}
              </Grid>,
              <Grid size={4} className={styles.rowLabel}>
                {metadata.name}
              </Grid>,
              <Grid size={2} className={styles.rowLabel}>
                {moment(metadata.created).format("MMMM DD, YYYY")}
              </Grid>,
              <Grid size={1} className={styles.rowLabel}>
                {metadata.suppliers}
              </Grid>,
              <Grid size={1} className={styles.rowLabel}>
                {metadata.items}
              </Grid>,
              <Grid size={1} className={styles.rowLabel}>
                {metadata.quantity}
              </Grid>,
              <Grid size={2} className={styles.rowLabel}>
                {buildPrice(metadata.estimates)}
              </Grid>,
              <Grid
                size={1}
                className={styles.rowLabel}
                sx={{ display: "flex", justifyContent: "flex-end" }}
              >
                <ConfirmableActionButton
                  onClick={() => {
                    showLoading();
                    removeChecklist(metadata.uuid)
                      .then(() => {
                        setChecklists(
                          checklists.filter(
                            (it) => it.metadata.uuid !== metadata.uuid
                          )
                        );
                      })
                      .finally(hideLoading);
                  }}
                  message={`Removing your checklist will permanently delete all details, and this action cannot be undone. Are you sure you want to proceed?`}
                >
                  <ClearIcon htmlColor="#0d6986" fontSize="small" />
                </ConfirmableActionButton>
                <IconButton
                  size="small"
                  onClick={() => navigate("/checklist/" + metadata.uuid)}
                >
                  <KeyboardArrowRightIcon
                    htmlColor="#0d6986"
                    fontSize="small"
                  />
                </IconButton>
              </Grid>,
              <Grid size={13} className={styles.rowLine} />,
            ];
          })}
        </Grid>
      );
    } else {
      return (
        <div
          style={{
            flex: 1,
            paddingLeft: "10vw",
            paddingRight: "10vw",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <label
            style={{
              fontStyle: "italic",
              fontSize: "1.6vw",
              color: "#0D6986",
              textAlign: "center",
            }}
          >
            It looks like you don’t have any checklist at the moment. Explore
            available resources and create checklists to get started.
          </label>
        </div>
      );
    }
  }
};

const ChecklistsPage = () => {
  const [checklists, setChecklists] = useState();

  useEffect(() => {
    showLoading();
    fetchSummaries().then(setChecklists).finally(hideLoading);
  }, []);

  return (
    <div className={styles.page}>
      <NavigationPanelView />
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <label className={styles.label}>Resources</label>
        </div>
        <div className={styles.content}>
          <div className={styles.control}>
            <div style={{ width: "20vw" }}>
              <SearchView onSearch={(phrase) => {}} onSearchCancel={() => {}} />
            </div>
          </div>
          <ChecklistContent checklists={checklists} />
        </div>
      </div>
    </div>
  );
};

export default ChecklistsPage;
