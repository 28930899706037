import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import Grid from "@mui/material/Grid2";
import { Button } from "@mui/material";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import ChromaView from "./ChromaView";
import ResourceOverviewDialog from "./ResourceOverviewDialog";
import { showChecklistDialog } from "../dialog/ChecklistDialog";
import styles from "../style/card.profile.module.css";

const PriceView = ({ price }) => {
  const renderSymbol = (symbol) => {
    if (symbol === "EUR") {
      return "€";
    } else if (symbol === "USD") {
      return "$";
    } else {
      return (
        <label style={{ fontSize: "0.8vw", paddingRight: 1 }}>{symbol}</label>
      );
    }
  };

  if (price) {
    const { currency, amount } = price;
    return (
      <div className={styles.pricing}>
        <LocalOfferOutlinedIcon fontSize="small" htmlColor="#0d6986" />
        <label className={styles.label}>
          {renderSymbol(currency)}
          {amount}
        </label>
      </div>
    );
  } else {
    return <div />;
  }
};

const OutOfStockView = ({ assets }) => {
  if (!assets || assets.amount === 0) {
    return <label className={styles.outOfStock}>out of stock</label>;
  }
};

const ItemView = (props) => {
  const { name, image } = props.item;

  const buildDynamicStyle = () => {
    if (props.selected === props.item.uuid) {
      return {
        color: "#0d6986",
        border: "1px solid #0d6986",
      };
    } else {
      return {};
    }
  };

  if (image) {
    return (
      <NavLink
        to={"#"}
        style={buildDynamicStyle}
        className={styles.imageItem}
        onClick={props.onSelect}
      >
        <ChromaView src={image} defaultColor="#EEEEEE">
          <img
            style={{ maxWidth: "3vw", maxHeight: "3vw" }}
            src={image}
            alt=""
          />
        </ChromaView>
      </NavLink>
    );
  } else if (name) {
    return (
      <NavLink
        to={"#"}
        style={buildDynamicStyle}
        className={styles.textItem}
        onClick={props.onSelect}
      >
        {name.default}
      </NavLink>
    );
  }
};

const ProfileCardView = (props) => {
  const { name, items } = props.resource;
  const [selected, select] = useState();
  const [image, setImage] = useState(props.resource?.image?.cover);
  const [assets, setAssets] = useState(props.resource.assets);
  const [price, setPrice] = useState(props.resource.price);

  return (
    <Grid item>
      <ChromaView src={image} defaultColor="#EEEEEE">
        <div className={styles.image}>
          {items === undefined && <OutOfStockView assets={assets} />}
          <img
            style={{ maxWidth: "13vw", maxHeight: "35vh" }}
            src={image}
            alt=""
          />
          <div style={{ position: "absolute", right: 3, top: 3 }}>
            <ResourceOverviewDialog resource={props.resource} />
          </div>
          <div className={styles.itemContent}>
            {items?.map((item) => {
              return (
                <ItemView
                  item={item}
                  selected={selected?.uuid}
                  onSelect={() => {
                    select(item);
                    setImage(item.image || props.resource?.image?.cover);
                    setAssets(item.assets);
                    setPrice(item.price);
                  }}
                />
              );
            })}
          </div>
        </div>
        <div className={styles.content}>
          <label className={styles.name}>
            {`${name.default?.substring(0, 25)}${
              name.default?.length > 25 ? "..." : ""
            }`}
          </label>
          <div className={styles.control}>
            <PriceView price={price} />
            <Button
              variant="contained"
              disabled={
                price === undefined ||
                assets === undefined ||
                assets.amount === 0
              }
              sx={{
                boxShadow: "none",
                marginRight: "5px",
                textDecoration: "none",
                borderRadius: "5px",
                backgroundColor: "#0d6986",
                color: "white",
                fontWeight: "bold",
                padding: "5px",
                fontSize: "0.5vw",
                "&.MuiButtonBase-root:hover": {
                  boxShadow: "none",
                },
              }}
              onClick={() => {
                const { uuid, name } = props.resource;
                if (items && selected) {
                  showChecklistDialog(selected.uuid, selected.name);
                } else {
                  showChecklistDialog(uuid, name);
                }
              }}
            >
              order
            </Button>
          </div>
        </div>
      </ChromaView>
    </Grid>
  );
};

export default ProfileCardView;
