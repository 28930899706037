import React from "react";

export const renderSymbol = (symbol) => {
  if (symbol === "EUR") {
    return "€";
  } else if (symbol === "USD") {
    return "$";
  } else {
    return (
      <label
        style={{
          fontSize: "0.8vw",
          paddingRight: "3px",
        }}
      >
        {symbol}
      </label>
    );
  }
};

export const buildPrice = (pricings) => {
  if (pricings?.length === 1) {
    const price = pricings[0];
    return (
      <label>
        {renderSymbol(price.currency)}
        {price.amount}
      </label>
    );
  } else if (pricings?.length > 1) {
    return pricings.map((price, index) => {
      return (
        <label>
          {index !== 0 ? " + " : ""}
          {renderSymbol(price.currency)}
          {price.amount}
        </label>
      );
    });
  }
};
