import axiosClient from "../../common/client/AxiosClient";
import { authorisationToken } from "../../common/client/AuthClient";

export const fetchChecklist = (uuid) => {
  return authorisationToken()
    .then((token) =>
      axiosClient.get("/checklist/" + uuid, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    )
    .then((response) => {
      return new Promise((resolve, reject) => {
        try {
          resolve(response.data);
        } catch (error) {
          reject(error);
        }
      });
    });
};

export const updateQuantity = (uuid, item) => {
  return authorisationToken().then((token) =>
    axiosClient.patch("/checklist/" + uuid, item, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
  );
};

export const removeItem = (uuid, item) => {
  return authorisationToken().then((token) =>
    axiosClient.delete("/checklist/" + uuid + "/" + item, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
  );
};

export const removeChecklist = (uuid) => {
  return authorisationToken().then((token) =>
    axiosClient.delete("/checklist/" + uuid, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
  );
};
