import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import NavigationPanelView from "../../navigation/component/NavigationPanelView";
import EventIcon from "@mui/icons-material/Event";
import SellOutlinedIcon from "@mui/icons-material/SellOutlined";
import NumbersOutlinedIcon from "@mui/icons-material/NumbersOutlined";
import InventoryOutlinedIcon from "@mui/icons-material/InventoryOutlined";
import WarehouseOutlinedIcon from "@mui/icons-material/WarehouseOutlined";
import {fetchChecklist, removeItem, updateQuantity} from "../client/AxiosClient";
import {
  hideLoading,
  showLoading,
} from "../../common/dialog/ThreeCirclesLoading";
import {buildPrice, renderSymbol} from "../utils/PricingUtils";
import styles from "../style/checklist.module.css";
import Grid from "@mui/material/Grid2";
import moment from "moment/moment";
import ClearIcon from "@mui/icons-material/Clear";
import ConfirmableActionButton from "../component/ConfirmableActionButton";
import TextField from "@mui/material/TextField";
import { InputTheme } from "../../common/utils/ThemeUtils";
import {ThemeProvider} from "@mui/material";
import ChromaView from "../../common/component/ChromaView";
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';

const Metadata = (props) => {
  return (
    <Grid container className={styles.metadata} columns={10} spacing={0.5}>
      <Grid size={10}>
        <label className={styles.metadataLabel}>{props.label}</label>
      </Grid>
      <Grid>{props.children}</Grid>
      <Grid>
        <label className={styles.metadataValue}>{props.value}</label>
      </Grid>
    </Grid>
  );
};

const QuantityInput = (props) => {
  const [quantity, setQuantity] = useState(props.quantity)

  return (
      <TextField
          id="quantityAmount"
          name="quantityAmount"
          value={quantity}
          variant="outlined"
          size="small"
          sx={{ flex: 1 }}
          onChange={event => {
            setQuantity(event.target.value)
          }}
          slotProps={{
            htmlInput: {
              type: "number",
            },
            input: {
              endAdornment: (props.quantity !== quantity) && (
                  <div style={{marginLeft: 5, marginRight: -5}}>
                    <ConfirmableActionButton onClick={() => {
                      showLoading();
                      updateQuantity(props.uuid, {uuid: props.item, fulfillment: {quantity: quantity}}).then(() => props.onChange(quantity)).finally(hideLoading);
                    }} message={`Updating the requested quantity will modify your current selection, and this action cannot be undone. Are you sure you want to proceed?`}>
                      <CheckOutlinedIcon fontSize="small"/>
                    </ConfirmableActionButton>
                  </div>
              ),
            }
          }}
      />
  )
}

const ChecklistPage = () => {
  const { uuid } = useParams();
  const [checklist, setChecklist] = useState();
  const { metadata, itemListings } = checklist || {
    metadata: {},
    itemListings: [],
  };

  useEffect(() => {
    showLoading();
    fetchChecklist(uuid).then(setChecklist).finally(hideLoading);
  }, []);

  return (
    <div className={styles.page}>
      <NavigationPanelView />
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <label className={styles.label}>Resources</label>
        </div>
        <div className={styles.content}>
          <div className={styles.headerComponent}>
            <label className={styles.name}>{metadata.name}</label>
            <NavLink to={"#"} className={styles.button} onClick={() => {}}>
              create order
            </NavLink>
          </div>
          <Grid container spacing={3} sx={{ marginTop: "1vw" }}>
            <Grid>
              <Metadata label="Created at" value={moment(metadata.created).format("DD MMMM YYYY")}>
                <EventIcon fontSize="small" htmlColor="#333333" />
              </Metadata>
            </Grid>
            <Grid>
              <Metadata label="Total suppliers" value={metadata.suppliers}>
                <WarehouseOutlinedIcon fontSize="small" htmlColor="#333333" />
              </Metadata>
            </Grid>
            <Grid>
              <Metadata label="Total items" value={metadata.items}>
                <NumbersOutlinedIcon fontSize="small" htmlColor="#333333" />
              </Metadata>
            </Grid>
            <Grid>
              <Metadata label="Total quantity" value={metadata.quantity}>
                <InventoryOutlinedIcon fontSize="small" htmlColor="#333333" />
              </Metadata>
            </Grid>
            <Grid>
              <Metadata label="Estimated price" value={buildPrice(metadata.estimates)}>
                <SellOutlinedIcon fontSize="small" htmlColor="#333333" />
              </Metadata>
            </Grid>
          </Grid>
          <ThemeProvider theme={InputTheme}>
          <Grid container columns={13} className={styles.scrollable} sx={{padding: "1vw"}}>
            <Grid size={5} className={styles.headerLabel}>
              Item
            </Grid>
            <Grid size={1} className={styles.headerLabel}>
              Price
            </Grid>
            <Grid size={2} className={styles.headerLabel}>
              Quantity
            </Grid>
            <Grid size={1} className={styles.headerLabel}>
              Total
            </Grid>
            <Grid size={1} className={styles.headerLabel} />
            <Grid size={3} className={styles.headerLabel} sx={{ display: "flex", justifyContent: "center" }}>
              Supplier
            </Grid>
            {itemListings.map((itemListing) => {
              const {account, items} = itemListing;
              return [
                <Grid size={13} sx={{padding: 1}}/>,
                <Grid size={10}>
                  <Grid container columns={10}>
                    {items.map((item) => {
                      const {fulfillment} = item;
                      return [
                        <Grid size={5} className={styles.itemRow}>
                          <ChromaView src={item.image} defaultColor="#EEEEEE">
                            <div style={{width: "5vw"}}>
                              <img style={{maxWidth: "5vw", maxHeight: "10vw"}} src={item.image} alt={""}/>
                            </div>
                          </ChromaView>
                          <div className={styles.attributesComponent}>
                            <label className={styles.itemNameLabel}>{item.name}</label>
                            <label className={styles.itemDescriptionLabel}>{item.description?.length > 300 ? `${item.description.substring(0, 300)}...` : item.description}</label>
                          </div>
                        </Grid>,
                        <Grid size={1} className={styles.rowLabel}>
                            <label style={{fontWeight: "bold"}}>
                                {renderSymbol(fulfillment.price.currency)}{fulfillment.price.amount}
                            </label>
                        </Grid>,
                        <Grid size={2} className={styles.rowLabel} sx={{paddingRight: "2vw"}}>
                          <QuantityInput quantity={fulfillment.quantity} uuid={uuid} item={item.uuid} onChange={quantity => {}}/>
                        </Grid>,
                          <Grid size={1} className={styles.rowLabel}>
                              <label style={{fontWeight: "bold"}}>
                                  {renderSymbol(fulfillment.price.currency)}{fulfillment.price.amount * fulfillment.quantity}
                              </label>
                          </Grid>,
                          <Grid size={1} className={styles.rowLabel}>
                              <ConfirmableActionButton onClick={() => {
                                showLoading();
                                removeItem(uuid, item.uuid).finally(hideLoading);
                              }} message={`Removing this item will permanently delete it from your checklist, and this action cannot be undone. Are you sure you want to proceed?`}>
                                  <ClearIcon htmlColor="#333333" fontSize="small" />
                              </ConfirmableActionButton>
                          </Grid>,
                        <Grid size={10} sx={{padding: 1}}/>,
                      ];
                    })}
                  </Grid>
                </Grid>,
                <Grid size={3} className={styles.accountRow}>
                  <div className={styles.accountComponent}>
                    <img style={{maxWidth: "10vw", maxHeight: "10vw"}} src={account.logo}/>
                    <label className={styles.accountNameLabel}>{account.name}</label>
                    <label className={styles.accountPhoneLabel}>{account.phone}</label>
                    <label className={styles.accountAddressLabel}>{account.address}</label>
                  </div>
                  <div className={styles.listingSummary}>
                    <label className={styles.listingSummaryLabel}>Total items: {items.length}</label>
                    <label className={styles.listingSummaryLabel}>Total amount: {renderSymbol(items[0].fulfillment.price.currency)}{items.map(item => item.fulfillment).map(fulfillment => fulfillment.quantity * fulfillment.price.amount).reduce((actual, value) => actual + value)}</label>
                  </div>
                </Grid>,
              ];
            })}
          </Grid>
          </ThemeProvider>
        </div>
      </div>
    </div>
  );
};

export default ChecklistPage;
