import { useEffect, useRef, useState } from "react";

/**
 * @deprecated use common chroma view
 *
 * Chroma view is able to handle the image background color, align the background
 * color of image and the border of entire view to the detected color. For white or
 * transparent background will set props.defaultColor and do not support gradients.
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const ChromaView = (props) => {
  const canvasRef = useRef(null);
  const [bgColor, setBgColor] = useState(props.defaultColor);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    const image = new Image();
    image.crossOrigin = "Anonymous";
    image.src = props.src;
    image.onload = () => {
      // Draw the image onto the canvas
      ctx.drawImage(image, 0, 0);
      // Get the pixel data of the top-left corner (position: 0,0)
      const pixelData = ctx.getImageData(0, 0, 1, 1).data;
      // Extract RGB values from the pixel data
      const [red, green, blue] = pixelData;
      // Convert RGB values to hex code
      const hexCode = rgbToHex(red, green, blue);
      if (hexCode !== "#000000" && hexCode !== "#ffffff") setBgColor(hexCode);
    };
  }, [props.src]);

  const rgbToHex = (r, g, b) =>
    `#${((r << 16) | (g << 8) | b).toString(16).padStart(6, "0")}`;

  return (
    <div
      className={props.className}
      style={{
        backgroundColor: bgColor,
        borderRadius: 5,
        border: `2px solid ${bgColor}`,
      }}
    >
      <canvas style={{ display: "none" }} ref={canvasRef} />
      {props.children}
    </div>
  );
};

export default ChromaView;
