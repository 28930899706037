import axiosClient, { executeGetJsonStreamRequest } from "./AxiosClient";
import { authorisationToken } from "./AuthClient";

export const fetchSummaries = () => {
  return executeGetJsonStreamRequest("/checklist/summary");
};

export const createChecklist = (checklist) => {
  return authorisationToken().then((token) =>
    axiosClient.post("/checklist", checklist, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  );
};

export const createItemListing = (uuid, item) => {
  return authorisationToken().then((token) =>
    axiosClient.post("/checklist/" + uuid, item, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
  );
};
