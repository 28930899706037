import { NavLink } from "react-router-dom";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { Path } from "../../checklist/utils/PathUtils";
import styles from "../style/option.module.css";

const ChecklistOption = () => {
  return (
    <NavLink
      to={Path.CHECKLISTS}
      className={(props) => {
        const stateStyle = props.isActive ? styles.active : styles.inactive;
        return `${styles.option} ${stateStyle}`;
      }}
    >
      <AssignmentIcon />
      checklists
    </NavLink>
  );
};

export default ChecklistOption;
